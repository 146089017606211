import React from 'react';
import { Switch } from '@headlessui/react';

interface ToggleProps {
  isDark: boolean;
  setIsDark: React.Dispatch<React.SetStateAction<boolean>>;
}

const Toggle = ({ isDark, setIsDark }: ToggleProps) => {
  return (
    <Switch
      checked={isDark}
      onChange={setIsDark}
      className={`${
        isDark ? 'bg-c1' : 'bg-slate-800'
      } relative inline-flex items-center h-6 rounded-full w-11`}
    >
      <span className="sr-only">Toggle Dark Mode</span>
      <span
        className={`${
          isDark ? 'translate-x-6' : 'translate-x-1'
        } inline-block w-4 h-4 transform bg-white rounded-full`}
      />
    </Switch>
  );
};

export default Toggle;

import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { DotsVerticalIcon } from '@heroicons/react/outline';

const Header = () => {
  return (
    <header>
      <div className="flex items-center w-full p-4">
        <Link to="/">
          <StaticImage
            className="w-full sm:h-full"
            imgClassName="rounded-full border-2 border-slate-200"
            src="../images/me.jpg"
            width={100}
            height={100}
            alt="Profile picture"
            loading="eager"
            placeholder="none"
          />
        </Link>
        <div className="flex items-center justify-between -ml-8 p-3 pl-12 w-full rounded-lg text-ctl dark:text-cdtl bg-slate-800">
          <div>
            <div className="font-sans font-bold ">Eric Andrae</div>
            <div className="mt-1 text-sm font-extralight dark:text-slate-400 ">Just testing stuff out!</div>
          </div>
          <nav className="flex font-sans items-center ">
            <div className="hidden sm:block space-x-4">
              <Link to="/">Home</Link>
              <Link to="/test1">Test1</Link>
              <Link to="/test2">Test2</Link>
            </div>
            <DotsVerticalIcon className="sm:hidden h-6 w-6 sm:h-7 sm:w-7 text-primary"></DotsVerticalIcon>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';

const Footer = () => {
  return (
    <footer className="flex pt-4 pb-6 font-sans text-sm text-slate-800 dark:text-slate-300 bg-transparent">
      <p className="mx-auto">
        Copyright © {new Date().getFullYear()},{` `}
        <a href="https://www.ericandrae.com">Eric Andrae</a>
      </p>
    </footer>
  );
};

export default Footer;

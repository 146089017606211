import * as React from 'react';
import { PageProps, Link } from 'gatsby';
import Header from './header';
import Footer from './footer';
import Toggle from './toggle';

type LayoutProps = {
  children: any;
};

const Layout = ({ children }: LayoutProps) => {
  const [isDark, setIsDark] = React.useState<boolean>(false);
  return (
    <div className={isDark ? 'dark' : ''}>
      <div className="font-serif w-100 h-100 bg-gray-100  dark:bg-slate-900">
        <div className="container mx-auto dark:bg-slate-700">
          <Header />
          <main className="p-4">{children}</main>
          <Footer />
          <div className="flex justify-center pb-4">
            <Toggle isDark={isDark} setIsDark={setIsDark} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
